<template>
  <div>
    <div class="quick_wrap">
      <p class="title">{{ $t("scrum.editScrumPage.head1") }}</p>
      <div class="button_wrap">
        <el-button icon="iconfont icon-exit" @click="cancel">{{
          $t("btn.exitEditBtn")
        }}</el-button>
        <!-- <div class="save_button" @click="save"><svg-icon style="width: 20px; height: 20px;margin-bottom: -2px; margin-right:10px" icon-class="save_white"></svg-icon>保存</div> -->
      </div>
      <div class="main_wrap">
        <el-form
          ref="form_item"
          :model="form_item"
          :rules="rules"
          label-width="160px"
          label-position="left"
        >
          <div>
            <el-form-item prop="name">
              <template slot="label">
                <span class="label-weight"
                  >{{ $t("scrum.editScrumPage.name") }}:</span
                >
              </template>
              <div>
                <el-input
                  v-model="form_item.name"
                  :placeholder="$t('placeholder.input')"
                  size="small"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="time">
              <template slot="label">
                <span class="label-weight"
                  >{{ $t("scrum.editScrumPage.date") }}:</span
                >
              </template>
              <div>
                <el-date-picker
                  v-model="form_item.time"
                  :disabled="is_disabled"
                  type="datetimerange"
                  range-separator="-"
                  :start-placeholder="$t('scrum.newSprint.start')"
                  :end-placeholder="$t('scrum.newSprint.end')"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="description">
              <template slot="label">
                <span class="label-weight"
                  >{{ $t("scrum.editScrumPage.goal") }}:</span
                >
              </template>
              <div class="block_warp">
                <textarea
                  v-model="form_item.description"
                  type="textarea"
                  size="small"
                  :autosize="{ maxRows: 6 }"
                  style="font-size: 16px"
                >
                </textarea>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { edit_sprint } from "@/network/quick/index.js";
import { mapActions } from "vuex";
export default {
  name: "Quickedit",
  components: {},
  data() {
    return {
      resend: {},
      form_item: {
        name: "",
        time: [],
        description: "",
        startDateTime: "",
        endDateTime: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    is_disabled() {
      return this.form_item.status !== "NOT_STARTED";
    },
  },
  mounted() {
    this.set_info();
  },
  methods: {
    ...mapActions(["setSprint"]),
    cancel() {
      this.settings().then(() => {
        if (this.form_item.name.trim() === "") {
          return;
        }
        this.$router.back();
      });
    },
    save() {},
    // 设置初始化信息
    set_info() {
      if (this.$route.params.data) {
        this.resend = this.$route.params.data;
        this.form_item.name = this.$route.params.data.name;
        this.form_item.build_name = this.$route.params.data.build_name;
        this.$set(this.form_item, "status", this.$route.params.data.status);
        this.form_item.description = this.$route.params.data.description;
        this.form_item.startDateTime = this.$route.params.data.startDateTime;
        this.form_item.endDateTime = this.$route.params.data.endDateTime;
        this.form_item.select_model = this.$route.params.data.select_model;
        if (this.form_item.startDateTime && this.form_item.endDateTime) {
          this.form_item.time.push(this.form_item.startDateTime);
          this.form_item.time.push(this.form_item.endDateTime);
        }
      } else {
        this.$router.push({
          name: "board",
          params: {
            data: this.resend,
          },
        });
      }
    },
    //设置信息
    async settings() {
      this.form_item.startDateTime = this.form_item.time[0];
      this.form_item.endDateTime = this.form_item.time[1];
      if (this.form_item.name.trim() === "") {
        return;
      }
      const params = {
        projectId: this.get_pid(),
        sprintId: this.form_item.select_model,
        data: {
          description: this.form_item.description,
          endDateTime: this.form_item.endDateTime,
          name: this.form_item.name,
          startDateTime: this.form_item.startDateTime,
        },
      };
      edit_sprint(params).then((res) => {
        this.setSprint(this.get_pid());
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.quick_wrap {
  text-align: left;
  height: 100%;
  overflow: auto;
  position: relative;
  .title {
    color: rgba(56, 56, 56, 1);
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
  }
  .button_wrap {
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    .save_button {
      color: #fff;
      background: rgba(48, 100, 143, 1);
      border-radius: 4px;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      width: 80px;
      text-align: center;
    }
  }
  .main_wrap {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    margin-top: 32px;
    border-radius: 4px;
    padding: 40px 35px;
    & > div {
      margin-bottom: 38px;
      display: flex;
      align-items: baseline;
      &:last-of-type {
        align-items: flex-start;
      }
      & > span {
        display: inline-block;
        width: 100px;
        color: rgba(112, 112, 112, 1);
        font-size: 16px;
      }
      & > div {
        width: 370px;
      }
    }
    .block_warp {
      width: 100%;
    }
  }
}

textarea {
  font-family: Arial, Helvetica, sans-serif !important;
  outline-color: skyblue;
  outline: none;
  margin-left: 2px;
  min-height: 201.989px;
  height: 201.989px;
  // height: auto;
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;

  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.label-weight {
  font-weight: bold;
}
</style>
